<template>
  <div  style="background: #f5f5f5">
    <van-nav-bar title="选择项目" left-arrow :fixed="true" :z-index="999" @click-left="back">
      <!-- <van-icon name="plus" slot="right" /> -->
    </van-nav-bar>
    <div style="padding-top:46px;background: #f5f5f5;">
      <div v-for="item in validList" :key="item.comboCustomerId">
        <van-cell-group class="m-t-10 p-10 p-l-5" style="border-left: 4px solid #DF3D48;">
          <van-cell
            :title="item.comboName"
            :icon="tcimg"
            :to="{name: 'meal_info', params: { id: item.comboCustomerId }}"
            style=" height: 30px; line-height: 30px;padding: 0; padding-left: 5px;"
          >
            <!-- <em>
              <span style="color:#01b185;padding-right:10px;">使用详情</span>
            </em>-->
          </van-cell>
        </van-cell-group>
        <van-cell-group>
          <van-cell>
            <van-row type="flex" justify="space-between">
              <van-col span="6">办理门店:</van-col>
              <van-col span="18" style="text-align:right;">{{item.garageName}}</van-col>
            </van-row>
          </van-cell>
          <van-cell>
            <van-row type="flex" justify="space-between">
              <van-col span="6">办理车牌:</van-col>
              <van-col span="18" style="text-align:right;">
                <span v-if="item.isLimitCarNo== 1">（限此车牌使用）</span>
                {{item.carNo}}
              </van-col>
            </van-row>
          </van-cell>
          <van-cell>
            <van-row type="flex" justify="space-between">
              <van-col span="6">起止日期:</van-col>
              <van-col
                span="18"
                style="text-align:right;"
              >{{item.startDate.substring(0,10)+' 至 '+item.endDate.substring(0,10)}}</van-col>
            </van-row>
          </van-cell>

          <van-cell v-for="(v,idx) in item.comboItemList" :key="idx">
            <van-row type="flex" justify="space-between">
              <van-col span="6">{{v.itemName}}</van-col>
              <van-col span="6" style="text-align:center;">
                <span style="color:#DF3D48;">{{v.totalTimes}}次</span>
                
              </van-col>
              <van-col span="7" style="text-align:right;">
                <img class="yu left" src="../../assets/img/yu.png" v-if="v.isok" alt />
                <span style="vertical-align: middle;">剩余{{v.surplusTimes}}次</span>
                <van-checkbox
                  class="ches"
                  @click="dianji(v,idx)"
                  checked-color="#0D906E"
                  :disabled="!v.isok"
                  v-model="v.checked"
                ></van-checkbox>
              </van-col>
            </van-row>
          </van-cell>
        </van-cell-group>
      </div>
    </div>
    <div style="height: 42px;"></div>
    <van-button class="bottd" type="danger" @click="yuyue" size="normal">选择施工时间</van-button>
  </div>
</template>

<script>
import userModel from "../../api/user";

export default {
  data() {
    return {
      active: 0,
      isok: false,
      checked: false,
      validList: [],
      list: [],
      tcimg: require("../../assets/img/tc.png"),
      chexin: JSON.parse(sessionStorage.getItem("chexin"))
        ? JSON.parse(sessionStorage.getItem("chexin"))
        : "",
      shangjia: JSON.parse(sessionStorage.getItem("shangjia")),
      userInfo: JSON.parse(localStorage.getItem("userInfo"))
    };
  },
  methods: {
    onLoad() {
      console.log(this.userInfo);
      //   var carNo = this.userInfo.mallUserCarInfoDO?this.userInfo.mallUserCarInfoDO.carNo:''
      //   console.log(carNo)
      const loading = this.$toast.loading({
        mask: true,
        duration: 0,
        message: "加载中..."
      });
      userModel
        .findByPhone({
          gid: this.shangjia.id,
          // phone: 15350281269
          phone: this.userInfo ? this.userInfo.phone : ""
        })
        .then(e => {
          loading.clear();
          this.validList = e.data.validList;
          this.validList.forEach(function(item, index) {
            console.log(item);
            item.comboItemList.forEach(function(i, idx) {
              i.isok = true;
              if (i.surplusTimes == 0) {
                i.isok = false;
              }
              if (i.appointmentId == 0) {
                i.isok = false;
              }
              if (i.appointmentId == null) {
                i.isok = false;
              }
            });

            item.checked = false;
          });
        })
        .catch(() => loading.clear());
    },
    yuyue() {
      // console.log(this.list.length)
      if (this.list.length == 0) {
        this.$toast("请先选择预约项目！");
        return;
      }
      var station = {
        appointmentId: "",
        itemName: [],
        comboItemList: []
      };

      this.list.forEach(function(item, index) {
        if (item.appointmentId) {
          station.appointmentId += item.appointmentId + ",";
        }
        var lis = {
          customerComboId: item.comboCustomerId,
          itemId: item.itemId,
          itemName: item.itemName
        };
        station.comboItemList.push(lis);
        station.itemName.push(item.itemName);
      });
      if (station.appointmentId.length > 1) {
        station.appointmentId = station.appointmentId.substring(
          0,
          station.appointmentId.length - 1
        );
      }
      sessionStorage.setItem("station", JSON.stringify(station));
      console.log(station);
      this.$router.push({
        path: "order_yuyue",
        query: {
          isok: true,
          carNo: this.chexin.carNo,
          type: 99,
          rowtype: 2
        }
      });
    },
    dianji(v, idx) {
      var _this = this;
      if (v.checked == true) {
        this.list.push(v);
      } else {
        _this.list.forEach(function(item, index) {
          if (item.id == v.id) {
            _this.list.splice(index, 1);
          }
        });
      }
      //   console.log(_this.list);
    },
    back() {
      this.$router.go(-1);
    }
  },
  created() {
    this.onLoad();
  }
};
</script>

<style lang="less" scoped>
.bottd {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 40px;
}
.yu {
  width: 20px;
  margin-top: 5px;
  vertical-align: middle;
  float: left;
}
.ches {
  display: inline-block;
  vertical-align: middle;
  margin-left: 5px;
}
.van-icon{
  height: 30px;
  vertical-align: middle;
}
.van-icon img{
  font-size: 22px;
  margin-top: 4px;
}
</style>